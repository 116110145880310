<div class="p-d-flex">
  <div class="w-100 p-mb-4">
    <p class="social-media p-d-flex p-jc-end">
      <a
        href="https://wa.me/557999985542?text"
        target="_blank"
        title="Contate-nos pelo WhatsApp"
        class="social-icon p-d-flex p-ai-center p-jc-center"
      >
        <span class="pi pi-whatsapp"></span>
      </a>
      <a
        href="https://t.me/+557999985542?text"
        target="_blank"
        title="Contate-nos pelo Telegram"
        class="social-icon p-d-flex p-ai-center p-jc-center"
        ><span class="pi pi-telegram"></span>
      </a>
      <a
        href="https://www.youtube.com/@chronosinfo.manager"
        target="_blank"
        title="Assine nosso canal do YouTube"
        class="social-icon p-d-flex p-ai-center p-jc-center"
        ><span class="pi pi-youtube"></span>
      </a>
      <a
        href="https://www.instagram.com/chronos.info/"
        target="_blank"
        title="Siga a gente no Instagram"
        class="social-icon p-d-flex p-ai-center p-jc-center"
      >
        <span class="pi pi-instagram"></span>
      </a>
    </p>
  </div>
</div>
