import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ErrorHandlerService } from '../core/service/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorHandlerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpEvent<any>) => this.errorHandler(error)));
  }

  private errorHandler(error: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      if (
        error.error instanceof Blob &&
        error.error.type === 'application/json'
      ) {
        return new Observable<HttpEvent<any>>((observer) => {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorMsg = JSON.parse(reader.result as string);
              this.errorService.handleError(
                new HttpErrorResponse({
                  error: errorMsg,
                  headers: error.headers,
                  status: error.status,
                  statusText: error.statusText,
                  url: error.url ?? '',
                })
              );
            } catch (e) {
              this.errorService.handleError(error);
            }
            observer.error(error);
          };
          reader.onerror = () => {
            this.errorService.handleError(error);
            observer.error(error);
          };
          reader.readAsText(error.error);
        });
      } else {
        // Se o erro for um erro HTTP, delegamos o tratamento para o serviço de erros
        this.errorService.handleError(error);

        // Verifica se o erro é 404 e retorna um objeto vazio
        if (error.status === 404) {
          return new Observable<HttpEvent<any>>((observer) => {
            observer.next({} as any); // Retorna um objeto vazio
            observer.complete();
          });
        }
      }
    }

    // Relança o erro para que outros interceptores ou componentes possam tratá-lo
    throw error;
  }
}
