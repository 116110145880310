import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class BlobErrorHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errMsg = "";

        if (
          error instanceof HttpErrorResponse &&
          error.error instanceof Blob &&
          error.error.type === "application/json"
        ) {
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = JSON.parse((<any>e.target).result);
                reject(
                  new HttpErrorResponse({
                    error: errmsg,
                    headers: error.headers,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url ?? undefined,
                  })
                );
              } catch (e) {
                reject(error);
              }
            };
            reader.onerror = (e) => {
              reject(error);
            };
            reader.readAsText(error.error);
          });
        }

        // Client Side Error
        if (error.error instanceof ErrorEvent) {
          errMsg = `Error: ${error.error.message}`;
        } else {
          // Server Side Error
          errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          return throwError(error);
        }
        return throwError(error);
      })
    );
  }
}
