import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { Filtro } from 'src/app/model/filtro';
import { NomeResponse } from './../models/nome.model';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EmpresaCnaeResponse } from 'src/app/view/cadastros/administrativos/empresa-page/models/empresa-cnae';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../seguranca/auth.service';
import { ChronosHttp } from '../../seguranca/chronos-http';
import {
  Empresa,
  EmpresaResponse,
} from '../../view/cadastros/administrativos/empresa-page/models/empresa';
import { ServiceBase } from './service-base.service';

@Injectable({
  providedIn: 'root',
})
export class EmpresaService extends ServiceBase<Empresa> {
  private empresa$?: Observable<EmpresaResponse>;

  private latestValue!: string | SafeUrl;

  constructor(
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    http: ChronosHttp
  ) {
    super('cadastros/administrativos/empresas', http);
  }

  override pesquisar(filtro: Filtro): Observable<Array<Empresa>> {
    let params = new HttpParams();

    let search = '';
    filtro.filtrosPes.forEach((f) => {
      search += `${f.nome}${f.operadorRelacional}${f.value},`;
    });

    if (filtro.filtrosPes.length > 0) {
      search = search.substring(0, search.length - 1);
      params = params.append('search', search);
    }

    return this.http.get<Array<Empresa>>(`${this.url}`, {
      params: params,
    });
  }

  getById(id: number): Observable<EmpresaResponse> {
    return this.http.get<EmpresaResponse>(`${this.url}/${id}`);
  }

  buscarPorNome(nome: string): Observable<Array<NomeResponse>> {
    const params = new HttpParams().set('filter', nome);

    return this.http.get<Array<NomeResponse>>(`${this.url}/nome`, {
      params: params,
    });
  }

  getAllCnaesByName(nome: string): Observable<NomeResponse[]> {
    const url = `${environment.apiUrl}/tabelas/cnaes/nome`;

    const params = new HttpParams().set('filter', nome);

    return this.http.get<NomeResponse[]>(url, { params: params });
  }

  getAllCnaesByIdEmpresa(id: number): Observable<EmpresaCnaeResponse[]> {
    return this.http.get<EmpresaCnaeResponse[]>(`${this.url}/${id}/cnaes`);
  }

  getEmpresa() {
    if (this.empresa$) {
      return this.empresa$;
    } else {
      return this.getById(this.authService.getIdEmpresa()).pipe(
        tap((result) => {
          this.empresa$ = of(result);
          return this.empresa$;
        }),
        share()
      );
    }
  }

  getLogoEmpresa(nameLogo: string) {
    return this.http
      .get<Empresa>(
        `${this.url}/${
          this.authService.jwtPayload!.idempresa
        }/logo/${nameLogo}`,
        { observe: 'response', responseType: 'blob' }
      )
      .pipe(
        // we map our blob into an ObjectURL
        map((response: any) => URL.createObjectURL(response.body)),
        // we bypass Angular's security mechanisms
        map((unsafeBlobUrl: string) =>
          this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)
        ),
        filter((blobUrl) => blobUrl !== this.latestValue),

        // if the request errors out we return the error image's path value
        catchError((error) => of(console.error(error)))
      );
  }
}
