import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
})
export class ToolbarComponent {
  usuario: string = '';
  constructor(
    public app: LayoutComponent,
    private router: Router,
    private authService: AuthService
  ) {
    this.usuario = this.authService.jwtPayload?.nome ?? 'Usuario';
  }

  logout() {
    this.authService.logout().then(() => this.router.navigate(['/login']));
  }
}
