import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Biblioteca } from 'src/app/util/biblioteca';
import { isPresent } from './funcoes';

type TypeValidation = 'required' | 'minlength' | 'maxlength' | 'min';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;

    const EMAIL_REGEXP =
      /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5})$/;
    const valid = EMAIL_REGEXP.test(control.value);
    return valid ? null : { emailInvalido: { value: control.value } };
  };
}

export function minLengthArray(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value.length >= min ? null : { MinLengthArray: true };
  };
}

export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const cpf = (control.value || '').replace(/\D/g, '').toString();
    const valid = cpf.length === 0 || Biblioteca.cpfValido(cpf);
    return valid ? null : { cpf: { value: control.value } };
  };
}

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;

    const cnpj = (control.value || '').replace(/\D/g, '').toString();
    const valid = cnpj.length === 0 || Biblioteca.cnpjValido(cnpj);
    return valid ? null : { cnpj: { value: control.value } };
  };
}

export function cpfCnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cpfCnpj = (control.value || '').replace(/\D/g, '').toString();
    const isCpf = cpfCnpj.length <= 11;
    const valid =
      cpfCnpj.length === 0
        ? true
        : cpfCnpj.length > 0 && cpfCnpj.length <= 11
        ? Biblioteca.cpfValido(cpfCnpj)
        : Biblioteca.cnpjValido(cpfCnpj);
    const error = isCpf
      ? { cpf: { value: control.value } }
      : { cnpj: { value: control.value } };
    return valid ? null : error;
  };
}

export function cepValidator(control: UntypedFormControl | AbstractControl) {
  const cep = control.value;
  if (cep && cep !== '') {
    const validacep = /^[0-9]{8}$/;
    return validacep.test(cep) ? null : { cepInvalido: true };
  }
  return null;
}

export function matchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (abstractControl: AbstractControl) => {
    const control = abstractControl.get(controlName);
    const matchingControl = abstractControl.get(matchingControlName);

    if (
      matchingControl!.errors &&
      !matchingControl!.errors?.[matchingControlName]
    )
      return null;

    if (control!.value !== matchingControl!.value) {
      const error = { confirmedValidator: 'Confirmação de senha invalida' };
      matchingControl!.setErrors(error);
      return error;
    } else {
      matchingControl!.setErrors(null);
      return null;
    }
  };
}

export function patternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const valid = regex.test(control.value);

    return valid ? null : error;
  };
}

export function getErrorMsg(
  fieldName: string,
  validatorName: TypeValidation,
  validatorValue?: any
) {
  const config = {
    required: `${fieldName} é obrigatório.`,
    minlength: `${fieldName} precisa ter no mínimo ${validatorValue.requiredLength} caracteres.`,
    maxlength: `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`,
    min: `${fieldName} precisa ter valor mínimo ${validatorValue.min}`,
    max: `${fieldName} precisa ter valor máximo ${validatorValue.max}`,
    cepInvalido: 'CEP inválido.',
    emailInvalido: 'Email inválido',
    email: 'Email inválido',
    cpf: 'CPF inválido',
    cnpj: 'CNPJ inválido',
  };

  return config[validatorName];
}
