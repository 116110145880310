import { Component, OnDestroy } from "@angular/core";
import { LoadingService } from "./../service/loading.service";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnDestroy {
  loading: boolean = false;

  constructor(private service: LoadingService) {
    setTimeout(() => {
      this.service.onLoadingChanged.subscribe(
        (isLoading: boolean) => (this.loading = isLoading)
      );
    });
  }

  ngOnDestroy(): void {
    this.loading = false;
  }
}
