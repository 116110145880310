import { Component } from '@angular/core';

@Component({
  selector: 'app-botao-rede-social',
  templateUrl: './botao-rede-social.component.html',
  styleUrls: ['./botao-rede-social.component.scss']
})
export class BotaoRedeSocialComponent {

}
