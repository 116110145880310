<p-toast />
<app-container-manager-login>
  <form
    class="signin-form p-fluid"
    autocomplete="off"
    [formGroup]="form"
    (submit)="onSubmit()"
  >
  <div class="form-group p-mb-3">
    <label class="label" for="password">Senha</label>
    <input
      type="password"
      pInputText
      formControlName="senha"
      name="senha"
      class="form-control"
      placeholder="Senha"

    />
    <div class="text-red-400 text-sm" *ngIf="senha?.dirty">
      <small class="p-d-block p-error" *ngIf="!senha?.value?.match('^(?=.*[A-Z])')">Pelo menos uma letra maiúscula.</small>
      <small class="p-d-block p-error" *ngIf="!senha?.value?.match('(?=.*[a-z])')">Pelo menos uma letra minúscula.</small>
      <small class="p-d-block p-error" *ngIf="!senha?.value?.match('(.*[0-9].*)')">Pelo menos um dígito.</small>
      <small class="p-d-block p-error" *ngIf="!senha?.value?.match('(?=.*[!@#$%^&*])')">Pelo menos um caracter special.</small>
      <small class="p-d-block p-error" *ngIf="!senha?.value?.match('.{8,}')">Minimo de 8 caracteres.</small>
   </div>
  </div>
  <div class="form-group p-mb-3">
    <label class="label" for="password">Confirmar Senha</label>
    <input
      type="password"
      pInputText
      formControlName="confirmarSenha"
      name="confirmarSenha"
      class="form-control"
      placeholder="Confirmar Senha"

    />
    <div class="text-red-400 text-sm" *ngIf="senha?.dirty">
      <small class="p-d-block p-error" *ngIf="form.errors?.['confirmedValidator']">{{form.errors?.['confirmedValidator']}}</small>
   </div>
  </div>
    <div class="form-group p-text-center">
      <button
        pButton
        style="height: 48px"
        type="submit"
        [disabled]="form.invalid"
        *ngIf="!loadding"
        label="Solicitar resert de senha"
      ></button>
      <p-progressSpinner
        strokeWidth="8"
        [style]="{ width: '50px', height: '50px' }"
        *ngIf="loadding"
      >
      </p-progressSpinner>
    </div>
  </form>
</app-container-manager-login>
