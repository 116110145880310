import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { AuthService } from '../../seguranca/auth.service';
import { EmpresaResponse } from '../../view/cadastros/administrativos/empresa-page/models/empresa';
import { EmpresaService } from './empresa.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  private empresa?: Observable<EmpresaResponse>;

  constructor(
    private empresaService: EmpresaService,
    private auth: AuthService
  ) {}

  clearEmpresa() {
    this.empresa = undefined;
  }

  getEmpresa() {
    if (this.empresa) return this.empresa;

    return this.empresaService.getById(this.auth.getIdEmpresa()).pipe(
      tap((result) => {
        this.empresa = of(result);
        return this.empresa;
      }),
      share()
    );
  }
}
