import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { criarQueryParams, Filtro } from 'src/app/model/filtro';
import { Page } from 'src/app/model/Page';
import { ChronosHttp } from 'src/app/seguranca/chronos-http';
import { environment } from 'src/environments/environment';

export class ServiceBase<T> {
  constructor(protected url: string, protected http: ChronosHttp) {
    this.url = `${environment.apiUrl}/${url}`;
  }

  pesquisar(filtro?: Filtro, isSpecification = true): Observable<Array<T>> {
    let params = new HttpParams();

    if (filtro) params = criarQueryParams(filtro, isSpecification, false);

    return this.http.get<Array<T>>(`${this.url}`, { params: params });
  }

  pesquisaPaginada(
    filtro: Filtro,
    isSpecification = true
  ): Observable<Page<T>> {
    let params = criarQueryParams(filtro, isSpecification, true);

    return this.http.get<Page<T>>(`${this.url}`, { params: params });
  }

  adicionar(obj: T): Observable<T> {
    return this.http.post<T>(this.url, obj);
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(`${this.url}/${id}`);
  }

  atualizar(id: number, obj: T) {
    return this.http.put<T>(`${this.url}/${id}`, obj);
  }

  excluir(id: number): Observable<void> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
