import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MensagemService } from './../../shared/services/mensagem.service';

@Component({
  selector: 'app-resert-senha',
  templateUrl: './resert-senha.component.html',
  styleUrls: ['./resert-senha.component.scss'],
})
export class ResertSenhaComponent {
  form = this.criarForm();
  loadding = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private mensagemService: MensagemService,
    private router: Router
  ) {}

  onSubmit() {
    this.loadding = true;
    this.auth
      .solicitarRecuperarSenha(this.form.value.usuario!)
      .subscribe({
        next: () => {
          this.mensagemService.showSuccess('Solitação realizada com sucesso!');
          this.router.navigate(['/login']);
        },
      })
      .add(() => (this.loadding = false));
  }

  private criarForm() {
    return this.fb.group({
      usuario: new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
    });
  }
}
