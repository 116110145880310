<div class="footer fixed">
  <div>
    <strong>Copyright </strong> ChronosInfo &copy; 2024
    <strong>Versão {{ version }}</strong>
  </div>

  <div class="pull-right">
    <span style="margin-right: 10px">{{ empresa }}</span>
    <strong>{{ dataAtual | date: 'dd/MM/yyyy HH:mm:ss' }}</strong>
  </div>
</div>
