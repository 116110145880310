<section class="section">
  <div class="container">
    <div class="p-grid p-jc-center">
      <div class="p-md-6 p-text-center p-mb-5">
        <h2 class="heading-section"></h2>
      </div>
    </div>
    <div class="p-grid p-jc-center">
      <div class="p-md-12 p-lg-10">
        <div class="wrap p-d-flex">
          <div class="img">
            <a href="https://chronosinfo.com.br/" target="_blank">
              <img src="assets/images/logo_login.png" />
            </a>
            <span class="p-text-center">Gestão Empresarial</span>
            <ul>
              <li>
                <i class="fa fa-sitemap signin-icon"></i> Sped Fiscal/Pis e
                Cofins
              </li>
              <li>
                <i class="fa fa-file-text-o signin-icon"></i> NF-e NFC-e CT-e
                MDF-e
              </li>
              <li>
                <i class="fa fa-outdent signin-icon"></i> Gestão na area
                Alimentícia
              </li>
              <li><i class="fa fa-user signin-icon"></i> Multi-Loja</li>
            </ul>
          </div>
          <div class="form-wrap p-p-5">
            <app-botao-rede-social></app-botao-rede-social>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
