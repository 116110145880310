import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isAccessTokenInvalido()) {
    return authService.obterNovoAccessToken().then(() => {
      if (authService.isAccessTokenInvalido()) {
        authService.redirectUrl = state.url;
        router.navigate(['/login']);

        return false;
      }

      return true;
    });
  } else {
    return new Observable<boolean>((sub) => {
      if (
        route.data['roles'] &&
        !authService.havePermission(...route.data['roles'])
      ) {
        sub.next(false);
        router.navigate(['/acesso-negado']);
      }
      sub.next(true);
    });
  }
};
