<p-toast />
<app-container-manager-login>
  <form
    class="signin-form p-fluid"
    autocomplete="off"
    [formGroup]="form"
    (submit)="onSubmit()"
  >
    <div class="form-group p-mb-4">
      <label class="label" for="name">Usuário</label>
      <input
        type="email"
        pInputText
        placeholder="email"
        name="usuario"
        formControlName="usuario"
        class="form-control"
      />
    </div>
    <div class="form-group p-text-center">
      <button
        pButton
        style="height: 48px"
        type="submit"
        [disabled]="form.invalid"
        *ngIf="!loadding"
        label="Solicitar resert de senha"
      ></button>
      <p-progressSpinner
        strokeWidth="8"
        [style]="{ width: '50px', height: '50px' }"
        *ngIf="loadding"
      >
      </p-progressSpinner>
    </div>
  </form>
</app-container-manager-login>
