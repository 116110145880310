import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  matchValidator,
  patternValidator,
} from 'src/app/shared/helper/validators';
import { MensagemService } from 'src/app/shared/services/mensagem.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-confirmar-resert-senha',
  templateUrl: './confirmar-resert-senha.component.html',
  styleUrls: ['./confirmar-resert-senha.component.scss'],
})
export class ConfirmarResertSenhaComponent implements OnInit {
  form = this.criarForm();
  loadding = false;

  token?: string;
  email?: string;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private mensagemService: MensagemService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const { token, email } = params;
      this.token = token;
      this.email = email;
    });
  }

  onSubmit() {
    if (!this.email) {
      this.mensagemService.showWarn('Email não informado!');
      return;
    }

    if (!this.token) {
      this.mensagemService.showWarn('Token não informado!');
      return;
    }

    if (this.form.invalid) return;

    this.loadding = true;

    this.auth
      .resetarSenha(this.email, this.form.value.senha!, this.token)
      .subscribe({
        next: () => {
          this.mensagemService.showSuccess(
            'Atualização de senha realizada com sucesso!'
          );
          this.router.navigate(['/login']);
        },
      })
      .add(() => (this.loadding = false));
  }

  private criarForm() {
    return this.fb.group(
      {
        senha: new FormControl<string>('', {
          validators: Validators.compose([
            Validators.required,
            Validators.minLength(8),
            patternValidator(new RegExp('(?=.*[0-9])'), {
              requiresDigit: true,
            }),
            patternValidator(new RegExp('(?=.*[A-Z])'), {
              requiresUppercase: true,
            }),
            patternValidator(new RegExp('(?=.*[a-z])'), {
              requiresLowercase: true,
            }),
            patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
              requiresSpecialChars: true,
            }),
          ]),
          nonNullable: true,
        }),
        confirmarSenha: new FormControl<string>('', {
          validators: [Validators.required],
          nonNullable: true,
        }),
      },
      {
        validators: matchValidator('senha', 'confirmarSenha'),
      }
    );
  }

  get senha() {
    return this.form.get('senha');
  }

  get confirmarSenha() {
    return this.form.get('confirmarSenha');
  }
}
