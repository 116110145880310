import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from '../shared/shared.module';
import { JwtInterceptor } from './Jwt-interceptor';
import { LoginComponent } from './login/login.component';
import { SegurancaRoutingModule } from './seguranca-routing.module';

import { ToastModule } from 'primeng/toast';
import { ResertSenhaComponent } from './resert-senha/resert-senha.component';
import { ConfirmarResertSenhaComponent } from './confirmar-resert-senha/confirmar-resert-senha.component';

export function tokenGetter() {
  return localStorage.getItem('chronos-erp-token');
}

@NgModule({
  imports: [
    SharedModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule,
    SegurancaRoutingModule,
  ],
  declarations: [LoginComponent, ResertSenhaComponent, ConfirmarResertSenhaComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
})
export class SegurancaModule {}
