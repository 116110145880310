import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MensagemService {
  constructor(private messageService: MessageService) {}

  showSuccess(msg: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Successo',
      detail: msg,
    });
  }

  showInfo(msg: string) {
    this.messageService.add({ severity: 'info', summary: 'Info', detail: msg });
  }

  showWarn(msg: string, key?: string) {
    this.messageService.add({
      key,
      severity: 'warn',
      summary: 'Aviso',
      detail: msg,
    });
  }

  showError(msg: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: msg,
    });
  }
}
