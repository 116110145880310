<div class="conteudo">
  <div class="gandalf">
    <div class="fireball"></div>
    <div class="skirt"></div>
    <div class="sleeves"></div>
    <div class="shoulders">
      <div class="hand left"></div>
      <div class="hand right"></div>
    </div>
    <div class="head">
      <div class="hair"></div>
      <div class="beard"></div>
    </div>
  </div>
  <div class="message">
    <h1>403 - Acesso Negado</h1>
    <p class="p-mt-5">
      Uh oh, sou o Gandalf e estou bloqueando o seu caminho!<br />
      Acho que está tentanto um caminho que você ainda não tem permissão
    </p>
    <p class="p-mt-5">
      <a class="p-button font-bold" [routerLink]="['/dashboard']">Voltar</a>
    </p>
  </div>
</div>
