import { Component } from '@angular/core';

@Component({
  selector: 'app-container-manager-login',
  templateUrl: './container-manager-login.component.html',
  styleUrls: ['./container-manager-login.component.scss']
})
export class ContainerManagerLoginComponent {

}
