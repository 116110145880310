import { LoadingComponent } from './loading/loading.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { MenuComponent, SubMenuComponent } from './menu/menu.component';
import { ProfileComponent } from './profile/profile.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';

import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    ScrollPanelModule,
    ToastModule,
    ConfirmDialogModule,
    ButtonModule,
    RouterModule,
    ProgressSpinnerModule
  ],
  declarations: [
    MenuComponent,
    FooterComponent,
    LayoutComponent,
    LoadingComponent,
    SubMenuComponent,
    ProfileComponent,
    ToolbarComponent,
    BreadcrumbComponent
  ]
})
export class LayoutModule { }
