import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';
import { ClockService } from '../service/clock.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  dataAtual: Date = new Date();

  version = environment.version;

  empresa: string = '';

  constructor(
    private service: ClockService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.empresa = this.authService.jwtPayload?.empresa ?? '';
    this.service.getClock().subscribe((time) => (this.dataAtual = time));
  }
}
