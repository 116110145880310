import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acesso-negado',
  templateUrl: './acesso-negado.component.html',
  styleUrls: ['./acesso-negado.component.sass']
})
export class AcessoNegadoComponent {

  constructor() { }


}
