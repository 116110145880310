<div class="topbar clearfix">
  <div class="topbar-left">
    <div class="logo"></div>
  </div>
  <div class="topbar-right">
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
      <i class="pi pi-chevron-left"></i>
    </a>

    <a id="rightpanel-menu-button" href="javascript:;" (click)="logout()">
      <i class="pi pi-power-off"></i>
    </a>
    <span
      style="
        margin-right: 20px;
        float: right;
        margin-top: 10px;
        font-size: larger;
        color: white;
      "
      >Bem vindo {{ usuario }}</span
    >
  </div>
</div>
